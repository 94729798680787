// React
import { useEffect, useRef, useState } from "react"

// Router
import { useHistory, useLocation } from "react-router-dom"

// Component
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap"
import { AiOutlineEdit, AiOutlinePrinter } from "react-icons/ai"
import { IoEyeOutline } from "react-icons/io5"
import {
  ActionButton,
  Alert,
  CreateButton,
  CRUDLayout,
  DataStatus,
  InputSearch,
  Pagination,
  ReadButton,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  UpdateButton
} from "../../../components"
import { DateConvert, TableNumber } from "../../../utilities"

// API
import Axios from "axios"
import {
  ItemBahanApi,
  ItemSubconApi,
  ItemUpahApi,
  PrakualifikasiVendorApi,
  VendorApi,
} from "../../../api"

// Icon
import { IoCheckmarkSharp } from "react-icons/io5"

// Print
import { useReactToPrint } from "react-to-print"
import CetakPrakualifikasiVendor from "./CetakPrakualifikasiVendor"

const PrakualifikasiVendor = ({ setNavbarTitle }) => {
  let history = useHistory();
  const location = useLocation();
  const paginationFromBrowserState = location?.state?.pagination;

  // indikator pemanggilan data sedang dimuat di server
  const [isLoading, setIsLoading] = useState(false);

  // indikator apakah sedang melakukan searching
  const [isSearching, setIsSearching] = useState(false);

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState("");
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [searchTime, setSearchTime] = useState(null);

  // Data View
  const [data, setData] = useState([]);
  console.log(data)
  const [page, setPage] = useState(paginationFromBrowserState?.page ?? 1);
  const [totalPage, setTotalPage] = useState(paginationFromBrowserState?.totalPage ?? 1);
  const [dataLength, setDataLength] = useState(paginationFromBrowserState?.dataLength ?? 10);
  const [dataCount, setDataCount] = useState(paginationFromBrowserState?.dataCount ?? 0);
  const [readModalShow, setReadModalShow] = useState(false);
  const [idVendor, setIdVendor] = useState("");
  const [idPrakualifikasiVendor, setIdPrakualifikasiVendor] = useState("");

  // alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  let createAlert = location?.state?.variant;

  // Print
  const [printData, setPrintData] = useState(0);
  const [printVendorData, setPrintVendorData] = useState(0);
  const [isPrint, setIsPrint] = useState(false);

  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);
    setShowAlert(false);

    PrakualifikasiVendorApi.getPage(page, dataLength, searchConfig.key)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setDataCount(res.data.data_count);
      })
      .catch((err) => alert(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value
    if(searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value
        });
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ` + key,
        });
        setShowAlert(key ? true : false);
      }, 750)
    )
  }

  const checkPaginationHandler = () =>
    new Promise((resolve, reject) => {
      if (Boolean(paginationFromBrowserState)) {
        setPage(paginationFromBrowserState.page);
        setDataLength(paginationFromBrowserState.dataLength);
        setTotalPage(paginationFromBrowserState.totalPage);
        setDataCount(paginationFromBrowserState.dataCount);

        resolve({
          page: paginationFromBrowserState.page,
          dataLength: paginationFromBrowserState.dataLength,
          totalPage: paginationFromBrowserState.totalPage,
          dataCount: paginationFromBrowserState.dataCount,
        });
      }

      reject({});
    });

  useEffect(() => {
    // set judul di Navbar
    setNavbarTitle("Prakualifikasi Vendor");

    if (createAlert !== undefined) {
      setAlertConfig({ variant: location?.state?.variant, text: location?.state?.text });
      setShowAlert(true);
    } else {
      return checkPaginationHandler().finally(() => getData());
    }

    // jalankan function request data ke server
    checkPaginationHandler().finally(() => getData());

    // menangani pembersihan saat fetching data
    return () => {
      history.replace((createAlert = undefined));
      setIsLoading(false);
    };
  }, [setNavbarTitle]);

  useEffect(() => {
    getData();
  }, [page, dataLength, searchConfig.key]);

  const componentRef = useRef();
  const reactToPrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setIsPrint(false),
  });

  const handleDataPrint = (id, id_vendor) => {
    setPrintData(id);
    setPrintVendorData(id_vendor);
    setIsPrint(true);
  };

  const handlePrint = () => {
    reactToPrint();
  };

  // MODAL DETAIL COMPONENT
  const ReadModal = () => {
    const [isLoadingRead, setIsLoadingRead] = useState(false);
    const [dataBahan, setDataBahan] = useState([]);
    const [dataUpah, setDataUpah] = useState([]);
    const [dataSubcon, setDataSubcon] = useState([]);
    const [readData, setReadData] = useState({
      dokumen_utama: [],
      dokumen_lainnya: [],
      jumlah_item: [],
    });
    const [vendorData, setVendorData] = useState({
      jenis_vendor: [],
    });

    useEffect(() => {
      setIsLoadingRead(true);

      getReadData();
      getDataBahan();
      getDataUpah();
      getDataSubcon();
    }, []);

    console.log("dataBahan", dataBahan);
    console.log("dataUpah", dataUpah);
    console.log("dataSubcon", dataSubcon);
    console.log("readData", readData);

    const getReadData = () => {
      Axios.all([
        PrakualifikasiVendorApi.single(idPrakualifikasiVendor),
        VendorApi.single(idVendor),
      ])
        .then(
          Axios.spread((res, vendor) => {
            setReadData(res.data.data);
            setVendorData(vendor.data.data);
          })
        )
        .catch(() => {
          setAlertConfig({
            variant: "danger",
            text: "Purchase Request gagal dimuat",
          });
        })
        .finally(() => {
          setIsLoadingRead(false);
        });
    };

    const getDataBahan = () => {
      ItemBahanApi.get(idVendor)
        .then((res) => setDataBahan(res.data.data))
        .catch((err) => alert(err));
    };

    const getDataUpah = () => {
      ItemUpahApi.get(idVendor)
        .then((res) => setDataUpah(res.data.data))
        .catch((err) => alert(err));
    };

    const getDataSubcon = () => {
      ItemSubconApi.get(idVendor)
        .then((res) => setDataSubcon(res.data.data))
        .catch((err) => alert(err));
    };

    const ShowData = ({ title, text }) => (
      <div className="d-flex flex-row">
        <div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
          {title}
        </div>
        <span>:</span>
        <div
          className="text-dark"
          style={{
            paddingLeft: 5,
          }}
        >
          {text ? text : "-"}
        </div>
      </div>
    );

    return (
      <>
        <Modal
          size="xl"
          show={readModalShow}
          onHide={() => {
            setReadModalShow(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Detail Data Prakualifikasi Vendor</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isLoadingRead == true ? (
              <DataStatus loading={true} text="Memuat data..." />
            ) : (
              <>
                <Row>
                  <Col lg="6">
                    <div className="mb-2">
                      <ShowData
                        title="Tanggal Prakualifikasi"
                        text={readData.tgl_vendor_prakualifikasi}
                      />

                      <ShowData
                        title="No Prakualifikasi"
                        text={readData.no_vendor_prakualifikasi}
                      />

                      <ShowData
                        title="Status Hukum Vendor"
                        text={readData.status_hukum ? "Berbadan Hukum" : "Tidak Berbadan Hukum"}
                      />
                    </div>

                    <div className="mb-2">
                      <ShowData title="Kode Vendor" text={vendorData.kode_vendor} />

                      <ShowData title="Nama Vendor" text={vendorData.nama_vendor} />

                      <ShowData
                        title="Jenis Vendor"
                        text={vendorData.jenis_vendor.map((val) => val.jenis_vendor).join(", ")}
                      />

                      <ShowData
                        title="Pimpinan Perusahaan"
                        text={vendorData.nama_pimpinan_perusahaan}
                      />

                      <ShowData title="Jumlah Karyawan" text={readData.jumlah_karyawan} />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="mb-2">
                      <ShowData title="Contact Person" text={vendorData.contact_person} />

                      <ShowData title="Telepon" text={vendorData.telepon} />

                      <ShowData title="Email" text={vendorData.email} />

                      <ShowData title="Website" text={vendorData.website} />

                      <ShowData title="Media Sosial" text={vendorData.media_sosial} />
                    </div>

                    <div className="mb-2">
                      <ShowData title="Alamat Vendor" text={vendorData.alamat_vendor} />

                      <ShowData title="Desa" text={vendorData.nama_desa} />

                      <ShowData title="Kecamatan" text={vendorData.nama_kecamatan} />

                      <ShowData title="Kabupaten" text={vendorData.nama_kabupaten} />

                      <ShowData title="Provinsi" text={vendorData.nama_provinsi} />

                      <ShowData title="Kode Pos" text={vendorData.kode_pos} />
                    </div>
                  </Col>
                </Row>

                {vendorData.jenis_vendor.map((value) => {
                  if (value.jenis_vendor == "Supplier") {
                    return (
                      <>
                        <small>List Item Bahan Yang Disediakan Vendor Ini Sebagai Supplier</small>
                        <CRUDLayout.Table>
                          <thead>
                            <tr>
                              <th>Kode Item Bahan</th>
                              <th>Nama Item Bahan</th>
                              <th>Kelompok Bahan</th>
                              <th>Jenis Bahan</th>
                              <th>Satuan Pakai</th>
                            </tr>
                          </thead>

                          <tbody>
                            {dataBahan.length > 0 ? (
                              dataBahan.map((val, index) => (
                                <tr key={index}>
                                  <td>{val.kode_item}</td>
                                  <td>{val.nama_item}</td>
                                  <td>{val.nama_kelompok}</td>
                                  <td>{val.nama_jenis}</td>
                                  <td>{val.satuan_pakai}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="6" className="my-2 font-weight-bold text-center">
                                  Tidak Ada Data
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </CRUDLayout.Table>
                      </>
                    );
                  } else if (value.jenis_vendor == "Mandor") {
                    return (
                      <>
                        <small>List Item Upah Yang Disediakan Vendor Ini Sebagai Mandor</small>
                        <CRUDLayout.Table>
                          <thead>
                            <tr>
                              <th>Kode Item Bahan</th>
                              <th>Nama Item Bahan</th>
                              <th>Satuan Pakai</th>
                            </tr>
                          </thead>

                          <tbody>
                            {dataUpah.length > 0 ? (
                              dataUpah.map((val, index) => (
                                <tr key={index}>
                                  <td>{val.kode_item}</td>
                                  <td>{val.nama_item}</td>
                                  <td>{val.nama_satuan}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="3" className="my-2 font-weight-bold text-center">
                                  Tidak Ada Data
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </CRUDLayout.Table>
                      </>
                    );
                  }
                })}

                <small>Jumlah Item Bahan Yang Akan Disediakan</small>
                <CRUDLayout.Table>
                  <thead>
                    <tr>
                      <th>Kode Item Bahan</th>
                      <th>Nama Item Bahan</th>
                      <th>Satuan Pakai</th>
                      <th>Jumlah</th>
                    </tr>
                  </thead>

                  <tbody>
                    {readData.jumlah_item.map((val, index) => (
                      <tr key={index}>
                        <td>{val.kode_item}</td>
                        <td>{val.nama_item}</td>
                        <td>{val.nama_satuan}</td>
                        <td className="text-right">{val.jumlah_item}</td>
                      </tr>
                    ))}
                  </tbody>
                </CRUDLayout.Table>

                <div className="mb-2">
                  <strong>Lama Vendor Menyediakan Item : </strong>
                  {readData.lama_menyediakan_item == "1"
                    ? "Kurang Dari 1 (Satu) Tahun"
                    : readData.lama_menyediakan_item == "2"
                    ? "Antara 1 (Satu) Sampai 3 (Tiga) Tahun"
                    : "Diatas 3 (Tiga) Tahun"}
                </div>

                <small>Kelengkapan Dokumen</small>
                <CRUDLayout.Table>
                  <THead>
                    <Tr>
                      <Th>No</Th>
                      <Th>Uraian</Th>
                      <Th>Ada</Th>
                      <Th>Tidak Ada</Th>
                    </Tr>
                  </THead>

                  <TBody>
                    {readData.dokumen_utama.map((val, index) => {
                      return (
                        <Tr key={index}>
                          <TdFixed>{index + 1}</TdFixed>
                          <Td>{val.nama_dokumen}</Td>
                          <TdFixed>{val.ada_atau_tidak ? <IoCheckmarkSharp /> : <div />}</TdFixed>
                          <TdFixed>{!val.ada_atau_tidak ? <IoCheckmarkSharp /> : <div />}</TdFixed>
                        </Tr>
                      );
                    })}
                  </TBody>
                </CRUDLayout.Table>

                {readData.dokumen_lainnya.length > 0 ? (
                  <>
                    <small>Dokumen Lainnya</small>
                    <CRUDLayout.Table>
                      <THead>
                        <Tr>
                          <Th>No</Th>
                          <Th>Uraian</Th>
                        </Tr>
                      </THead>

                      <TBody>
                        {readData.dokumen_lainnya.map((val, index) => {
                          return (
                            <Tr key={index}>
                              <TdFixed>{index + 1}</TdFixed>
                              <Td>{val.nama_dokumen}</Td>
                            </Tr>
                          );
                        })}
                      </TBody>
                    </CRUDLayout.Table>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  };

  // Tabel
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Prakualifikasi Vendor</ThFixed>
              <ThFixed>Kode Vendor</ThFixed>
              <Th>Nama Vendor</Th>
              <Th width={160}>Contact Person</Th>
              <Th width={160}>Telepon</Th>
              <Th width={130}>Status Kualifikasi</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
                <TdFixed>
                  <ButtonGroup>
                    <ReadButton
                    noMargin
                      onClick={() => {
                        setReadModalShow(true);
                        setIdVendor(val.id_vendor);
                        setIdPrakualifikasiVendor(val.id_vendor_prakualifikasi);
                      }}
                    />
                    
                    {isPrint === true ?
                      <CetakPrakualifikasiVendor
                        ref={componentRef}
                        idRef={printData}
                        idVendor={printVendorData}
                      /> : ""
                    }

                    <ActionButton
                      size="sm"
                      variant="warning"
                      onClick={() => {
                        handleDataPrint(val.id_vendor_prakualifikasi, val.id_vendor);
                        setTimeout(() => {
                          handlePrint();
                        }, 1500);
                      }}
                    >
                      <AiOutlinePrinter className="text-white" />
                    </ActionButton>

                    <UpdateButton
                      noMargin
                      variant="success"
                      onClick={() => {
                        history.push(
                          `/master/prakualifikasi-vendor/ubah/${val.id_vendor_prakualifikasi}`,
                          {
                            pagination: {
                              page: page,
                              totalPage: totalPage,
                              dataCount: dataCount,
                              dataLength: dataLength,
                            },
                          }
                        );
                      }}
                    >
                    </UpdateButton>
                  </ButtonGroup>
                </TdFixed>
                <TdFixed>
                  <div className="text-left">{val.tgl_vendor_prakualifikasi ? DateConvert(new Date(val.tgl_vendor_prakualifikasi)).defaultDMY : "-"}</div>
                  <div className="text-left">{val.no_vendor_prakualifikasi}</div>
                </TdFixed>
                <TdFixed>{val.kode_vendor ?? "-"}</TdFixed>
                <Td>{val.nama_vendor ?? "-"}</Td>
                <Td>{val.contact_person ?? "-"}</Td>
                <Td>{val.telepon ?? "-"}</Td>
                <Td>
                  <div className="text-center">
                    {val.status_kualifikasi == "0" ? <div>PENDING</div> : val.status_kualifikasi == "1"
                      ? <div className="text-success">LOLOS</div> : val.status_kualifikasi == "2"
                      ? <div className="text-danger">TIDAK LOLOS</div> : ""}
                  </div>
                  <div className="text-center">11/11/2002</div>
                </Td>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataNumber={page * dataLength - dataLength + 1}
            dataPage={page * dataLength}
            dataCount={dataCount}
            onDataLengthChange={(e) => {
              setDataLength(e.target.value);
              setPage(1);
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={onInputSearchChange}
                // onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <CreateButton
            color="primary"
            onClick={() =>
              history.push("/master/prakualifikasi-vendor/calon-vendor", {
                pagination: {
                  page: page,
                  totalPage: totalPage,
                  dataCount: dataCount,
                  dataLength: dataLength,
                },
                page: 1,
              })
            }
          >
            TAMBAH DATA
          </CreateButton>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {/* Table */}
      {
        // cek apakah data sedang dimuat (loading)
        isLoading === true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : // Cek apakah ada data
        data.length > 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      }

      {readModalShow && <ReadModal />}
    </CRUDLayout>
  );
};

export default PrakualifikasiVendor;


// <><div style={{ display: "none" }}>
// {/* </div></> */}
