import Axios from "axios"
import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { PurchaseOrderApi } from "../../../../api"
import {
  Alert,
  ApprovalStatusButton,
  CreateButton,
  CRUDLayout,
  DataStatus,
  FilterButton,
  InputSearch,
  Pagination,
  ReadButton,
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  UpdateButton
} from "../../../../components"
import { DateConvert, TableNumber } from "../../../../utilities"
import ModalFilter from "./ModalFilter"
import { ButtonGroup } from "react-bootstrap"

const PurchaseOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const paginationFromBrowserState = location?.state?.pagination;
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataPurchaseOrder, setDataPurchaseOrder] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: paginationFromBrowserState?.page ?? 1,
    dataLength: paginationFromBrowserState?.dataLength ?? 10,
    totalPage: paginationFromBrowserState?.totalPage ?? 1,
    dataCount: paginationFromBrowserState?.dataCount ?? 0,
    key: "",
  });
  const [dataFilter, setDataFilter] = useState({
    active: paginationFromBrowserState?.active,
    tgl_purchase_order_mulai:
      paginationFromBrowserState?.tgl_purchase_order_mulai,
    tgl_purchase_order_selesai:
      paginationFromBrowserState?.tgl_purchase_order_selesai,
    tgl_seleksi_vendor_mulai:
      paginationFromBrowserState?.tgl_seleksi_vendor_mulai,
    tgl_seleksi_vendor_selesai:
      paginationFromBrowserState?.tgl_seleksi_vendor_selesai,
    id_item_buaso: paginationFromBrowserState?.id_item_buaso,
    id_vendor: paginationFromBrowserState?.id_vendor,
    status_approval: paginationFromBrowserState?.status_approval,
  });
  const [modalFilter, setModalFilter] = useState(false);
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [searchTime, setSearchTime] = useState(null);
  const [dropdownFilter, setDropdownFilter] = useState({
    vendor: [],
    itemBuaso: [],
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PurchaseOrderApi.get({
      q: searchConfig?.key,
      page: paginationConfig?.page,
      per_page: paginationConfig?.dataLength,
      tgl_purchase_order_mulai: dataFilter?.tgl_purchase_order_mulai,
      tgl_purchase_order_selesai: dataFilter?.tgl_purchase_order_selesai,
      tgl_seleksi_vendor_mulai: dataFilter?.tgl_seleksi_vendor_mulai,
      tgl_seleksi_vendor_selesai: dataFilter?.tgl_seleksi_vendor_selesai,
      id_item_buaso: dataFilter?.id_item_buaso,
      id_vendor: dataFilter?.id_vendor,
      status_approval: dataFilter?.status_approval,
    })
      .then(({ data }) => {
        setDataPurchaseOrder(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => {
        setIsPageLoading(false);
      });
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value,
        });
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    );
  };
  const getDropdown = () => {
    Axios.all([
      PurchaseOrderApi.getDropdownVendor({ status_vendor: "1" }),
      PurchaseOrderApi.getDropdownItemBuaso(),
    ]).then(
      Axios.spread((dataVendor, buaso) => {
        const mappingVendor = (data) =>
          data.map((val) => ({
            label: val?.nama_vendor,
            value: val?.id_vendor,
          }));
        const mappingBuaso = (data) =>
          data.map((val) => ({
            label: val?.nama_item,
            value: val?.id_item_buaso,
          }));
        setDropdownFilter({
          vendor: mappingVendor(dataVendor?.data?.data ?? []),
          itemBuaso: mappingBuaso(buaso?.data?.data ?? []),
        });
      })
    );
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const checkPaginationHandler = () =>
    new Promise((resolve, reject) => {
      if (Boolean(paginationFromBrowserState)) {
        setPaginationConfig({
          page: paginationFromBrowserState.page,
          dataLength: paginationFromBrowserState.dataLength,
          totalPage: paginationFromBrowserState.totalPage,
          dataCount: paginationFromBrowserState.dataCount,
        });

        resolve({
          page: paginationFromBrowserState.page,
          dataLength: paginationFromBrowserState.dataLength,
          totalPage: paginationFromBrowserState.totalPage,
          dataCount: paginationFromBrowserState.dataCount,
        });
      }

      reject({});
    });

  useEffect(() => {
    setNavbarTitle("Purchase Order");
    checkPaginationHandler().finally(() => getInitialData());
    checkAlert();
    getDropdown();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
    dataFilter?.tgl_purchase_order_mulai,
    dataFilter?.tgl_purchase_order_selesai,
    dataFilter?.tgl_seleksi_vendor_mulai,
    dataFilter?.tgl_seleksi_vendor_selesai,
    dataFilter?.id_item_buaso,
    dataFilter?.id_vendor,
    dataFilter?.status_approval,
  ]);

  // useEffect(() => {
  //   getInitialData();
  // }, [searchConfig.key, paginationConfig.page, paginationConfig.dataLength]);

  const PageContent = () => {
    const getStatusProgress = (status) => {
      switch (status) {
        case "close":
          return {
            label: "CLOSED",
            color: "outline-danger",
          };
        case "onprogress":
          return {
            label: "ON PROGRESS",
            color: "outline-warning",
          };
        default:
          return {
            label: "PENDING",
            color: "outline-secondary",
          };
      }
    };

    const getStatusApprovalLabel = (value) => {
      switch (value) {
        case "APP":
          return {
            variant: "outline-success",
            label: "APPROVED",
            level: 4,
          };
        case "REJ":
          return {
            variant: "outline-danger",
            label: "REJECT",
            level: 3,
          };
        case "REV":
          return {
            variant: "outline-revised",
            label: "REVISI",
            level: 2,
          };
        case "VER":
          return {
            variant: "outline-verified",
            label: "VERIFIED",
            level: 4,
          };
        default:
          return {
            variant: "outline-secondary",
            label: "PENDING",
            level: 1,
          };
      }
    };

    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi Purchase Order</ThFixed>
            <Th>Vendor</Th>
            <Th>Tgl. Pengiriman</Th>
            <ThFixed>Status Progress</ThFixed>
            <ThFixed>Status Approval</ThFixed>
            {/* <Th>Status Purchase Request</Th> */}
          </Tr>
        </THead>
        <TBody>
          {dataPurchaseOrder.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </TdFixed>
              <TdFixed>
                <ButtonGroup>
                <ReadButton
                  onClick={() =>
                    history.push(
                      "/transaksi/purchase-order/detail/" +
                        val.id_purchase_order,
                      {
                        pagination: {
                          page: paginationConfig.page,
                          totalPage: paginationConfig.totalPage,
                          dataCount: paginationConfig.dataCount,
                          dataLength: paginationConfig.dataLength,
                        },
                      }
                    )
                  }
                />

                {val.status_approval === "REV" && (
                  <UpdateButton
                    onClick={() =>
                      history.push(
                        "/transaksi/purchase-order/ubah/" +
                          val.id_purchase_order,
                        {
                          pagination: {
                            page: paginationConfig.page,
                            totalPage: paginationConfig.totalPage,
                            dataCount: paginationConfig.dataCount,
                            dataLength: paginationConfig.dataLength,
                          },
                        }
                      )
                    }
                  />
                  )}
                </ButtonGroup>
              </TdFixed>
              <Td>
                <div>
                  {val.tgl_purchase_order
                    ? DateConvert(new Date(val.tgl_purchase_order)).defaultDMY
                    : "-"}
                </div>
                <div>{val.no_purchase_order}</div>
              </Td>
              <Td>
                <div style={{ width: "200px" }}>{val.nama_vendor}</div>
              </Td>
              <Td>
                {val.tgl_pengiriman
                  ? DateConvert(new Date(val.tgl_pengiriman)).defaultDMY
                  : "-"}
              </Td>
              <TdFixed>
                <ApprovalStatusButton
                  variant={getStatusProgress(val.status_purchase_order).color}
                >
                  {getStatusProgress(val.status_purchase_order).label}
                </ApprovalStatusButton>
              </TdFixed>
              <TdFixed>
                <ApprovalStatusButton
                  variant={getStatusApprovalLabel(val.status_approval).variant}
                >
                  {getStatusApprovalLabel(val.status_approval).label}
                </ApprovalStatusButton>
              </TdFixed>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearchChange} />

            <FilterButton
              active={dataFilter?.active}
              onClick={() => setModalFilter(!modalFilter)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push("/transaksi/purchase-order/vendor-terseleksi", {
                pagination: {
                  page: paginationConfig.page,
                  totalPage: paginationConfig.totalPage,
                  dataCount: paginationConfig.dataCount,
                  dataLength: paginationConfig.dataLength,
                },
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <div className="mt-2">
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
      </div>

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataPurchaseOrder ? (
        dataPurchaseOrder.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}

      {modalFilter && (
        <ModalFilter
          toggle={() => setModalFilter(!modalFilter)}
          data={dataFilter}
          setData={setDataFilter}
          buaso="upah"
          dropdown={dropdownFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default PurchaseOrder;
